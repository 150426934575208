import React from "react";
import SendSMS from "./SendSMS";

const Dashboard = (props: any) => {
  return (
    <div className="grid dashboard">
      <SendSMS />
    </div>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Dashboard, comparisonFn);
