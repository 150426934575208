import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import { formatString, memberLabelTemplate } from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import {
  CSS_COL_12,
  CSS_COL_3,
  CSS_COL_4,
  CSS_COL_6,
  CSS_COL_8,
  KNOWN_AGGREGATORS,
  MAXIMUM_RECORDS_PER_PAGE,
  MINIMUM_FILTER_QUERY_LENGTH,
  RECORD_STATUSES,
} from "../app_utils/constants/Constants";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  record: any;
  reloadFn: any;
  isOpen: boolean;
  toggle: () => void;
}

interface RecordType {
  id: number;
  name: string;
  description: string;
  baseUrl: string;
  apiUsername: string;
  apiPassword: string;
  priority: number;
  knownAggregator: string;
  dailySuccessRate: number;
  status: string;
  countryId: number;
  telecomCodes: string[];
}
const GatewayFormDialog = (props: ModalType) => {
  const [record, setRecord] = useState<RecordType | null>(props?.record);
  const [countries, setCountries] = useState<any>([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const message = useRef<any>();
  const fetchCountriesFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/countries")
      .getRequestWithJsonResponse({ offset: 0, limit: 500 })
      .then(async (response) => {
        setCountries(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called when the object is changed. This happens
   * in the parent view when the is changed
   */
  useEffect(() => {
    setRecord(props?.record);
    fetchCountriesFromServer();
  }, []);

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    setRecord(null);
  };

  /**
   * This is a list of user form fields
   */
  let userFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Name",
      value: record?.name,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, name: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Description",
      value: record?.description,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, description: e })),
      width: CSS_COL_8,
    },

    {
      type: FormFieldTypes.TEXT.toString(),
      label: "BaseUrl",
      value: record?.baseUrl,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, baseUrl: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.NUMBER.toString(),
      label: "Priority",
      value: record?.priority,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, priority: e })),
      width: CSS_COL_3,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Country",
      value: record?.countryId,
      options: countries,
      optionValue: "id",
      optionLabel: "name",
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, countryId: e })),
      width: CSS_COL_3,
    },
    {
      type: FormFieldTypes.CHIPSTEXT.toString(),
      label: "Supported Telecom Codes (e.g +256)",
      value: record?.telecomCodes,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, telecomCodes: e })),
      width: CSS_COL_12,
    },

    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Api Username",
      value: record?.apiUsername,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, apiUsername: e })),
      validateFieldFn: validateEmptyField,
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Api Password",
      value: record?.apiPassword,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, apiPassword: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Known Aggregators",
      value: record?.knownAggregator,
      options: KNOWN_AGGREGATORS,
      optionValue: "id",
      optionLabel: "name",
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, knownAggregator: e })),
      width: CSS_COL_3,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Status",
      value: record?.status,
      options: RECORD_STATUSES,
      optionValue: "id",
      optionLabel: "name",
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, status: e })),
      width: CSS_COL_3,
    },
  ];

  /**
   * This loops through the user object fields array to create the fields elements for
   * display
   */
  let userFields = userFormFields.map((userObjectField: any) => {
    return getFormFieldComponent(userObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    userFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    userFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null || formField.value === "" || formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(formatString(MISSING_FORM_INPUT_MESSAGE, formField.label));
      }
    });

    return isFormValid;
  };

  /**
   * This submits a save user request to the backoffice
   */
  const saveUser = () => {
    if (validateForm()) {
      setIsSaving(true);
      new BaseApiServiceImpl("/api/v1/gateways")
        .postRequestWithJsonResponse(record)
        .then(async (response) => {
          setIsSaving(false);
          clearForm();
          MessageUtils.showSuccessMessage(
            props?.messageRef,
            labels.LABEL_RECORD_SAVED_SUCCESSFULLY
          );
          closeDialog();
          props?.reloadFn();
        })
        .catch((error) => {
          setIsSaving(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggle();
  };

  /**
   * This is the footer of the modal dialog
   */
  const userDetailsDialogFooter = (
    <>
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveUser}
        loading={isSaving}
      />
    </>
  );

  return (
    <Dialog
      visible={props.isOpen}
      header={"Add/Edit SMS Gateway"}
      footer={userDetailsDialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>
        {userFields}
      </div>
    </Dialog>
  );
};

export default GatewayFormDialog;
