import { PrimeIcons } from "primereact/api";
import { Module } from "../../../app_utils/constants/Module";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import Login from "../../../pages/Login";
import {
  API_KEYS_ROUTE_PATH,
  GATEWAYS_ROUTE_PATH,
  HOME_ROUTE_PATH,
  LANDING_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  REGISTER_ROUTE_PATH,
  SEND_SMS_ROUTE_PATH,
  SMS_REPORTS_ROUTE_PATH,
  USERS_ROUTE_PATH,
} from "./PageRoutes";
import Dashboard from "../../../pages/Dashboard";
import GatewaysView from "../../../pages/GatewaysView";
import SendSMS from "../../../pages/SendSMS";
import SmsReportView from "../../../pages/SmsReportView";
import UsersView from "../../../pages/UsersView";
import ApiKeysView from "../../../pages/ApiKeysView";
export class MainFrontendRoutes {
  /**
   * Start of module section handler attributes
   */
  settingsModuleSelected = false;
  crmCoreModuleSelected = false;
  documentRepositoryModuleSelected = false;
  userDetails: any = {};
  appHistory: any = null;

  constructor(selectedModuleName: string) {
    this.settingsModuleSelected = selectedModuleName === Module.BACK_OFFICE.toString();
    this.crmCoreModuleSelected = selectedModuleName === Module.CRM_CORE.toString();
    this.documentRepositoryModuleSelected =
      selectedModuleName === Module.DOCUMENT_REPOSITORY.toString();
    this.userDetails = UserSessionUtils.getUserDetails();
  }

  /**
   * This checks whether a user can view the landing page. It checks whether a user has a specific
   * module or set of permissions that are allowed to view the landing page
   * @returns boolean
   */
  userCanViewLandingPage() {
    return true;
  }

  /**
   * From here we populate menu item for the selected module
   */
  getMainNavigationMenu() {
    let menu = [];

    menu.push({ label: "Home", icon: PrimeIcons.HOME, to: LANDING_ROUTE_PATH });
    menu.push({ label: "SMS Reports", icon: PrimeIcons.ENVELOPE, to: SMS_REPORTS_ROUTE_PATH });
    menu.push({ label: "Send SMS", icon: PrimeIcons.SEND, to: SEND_SMS_ROUTE_PATH });
    menu.push({ label: "Api Keys", icon: PrimeIcons.COG, to: API_KEYS_ROUTE_PATH });

    if (UserSessionUtils.isSuperAdmin() == true) {
      menu.push({ label: "Gateways", icon: PrimeIcons.COMPASS, to: GATEWAYS_ROUTE_PATH });
      menu.push({ label: "System Users", icon: PrimeIcons.USERS, to: USERS_ROUTE_PATH });
    }
    return menu;
  }

  /**
   *
   * @returns
   */
  getUnAuthenticatedRoutes() {
    return [
      { path: LOGIN_ROUTE_PATH, label: "Login", component: Login, exact: true },
      {
        path: REGISTER_ROUTE_PATH,
        label: "Landing",
        component: Login,
        exact: true,
      },
    ];
  }

  /**
   *
   * @returns
   */
  getAuthenticatedRoutes() {
    let menu = [];

    menu.push({ path: LOGIN_ROUTE_PATH, component: Login, exact: true });
    menu.push({
      path: HOME_ROUTE_PATH,
      component: Dashboard,
      exact: true,
    });

    menu.push({
      path: SEND_SMS_ROUTE_PATH,
      component: SendSMS,
      exact: true,
    });
    menu.push({
      path: SMS_REPORTS_ROUTE_PATH,
      component: SmsReportView,
      exact: true,
    });
    menu.push({
      path: LANDING_ROUTE_PATH,
      component: SendSMS,
      exact: true,
    });
    menu.push({
      path: API_KEYS_ROUTE_PATH,
      component: ApiKeysView,
      exact: true,
    });

    if (UserSessionUtils.isSuperAdmin()) {
      menu.push({
        path: USERS_ROUTE_PATH,
        component: UsersView,
        exact: true,
      });
      menu.push({
        path: GATEWAYS_ROUTE_PATH,
        component: GatewaysView,
        exact: true,
      });
    }
    return menu;
  }
}
