export const LOGIN_ROUTE_PATH = "/login";
export const HOME_ROUTE_PATH = "/home";
export const REGISTER_ROUTE_PATH = "/register";
export const LOOKUPS_ROUTE_PATH = "/lookups";
export const USERS_ROUTE_PATH = "/system-users";
export const API_KEYS_ROUTE_PATH = "/api-keys";
export const GATEWAYS_ROUTE_PATH = "/gateways";
export const SMS_REPORTS_ROUTE_PATH = "/sms-reports";
export const SEND_SMS_ROUTE_PATH = "/send-sms";
export const LANDING_ROUTE_PATH = "/";
