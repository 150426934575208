export enum FormFieldTypes {
    TEXT,
    DROPDOWN,
    MULTISELECT,
    DATE,
    NUMBER,
    FLOAT,
    TEXTAREA,
    BOOLEAN,
    CHIPSTEXT
};
