import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { HOME_ROUTE_PATH } from "../app_utils/route_paths/resolver/PageRoutes";
import { UserSessionUtils } from "../app_utils/utils/UserSessionUtils";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import { Messages } from "primereact/messages";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // Customize as needed

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const history = useHistory();

  const message = useRef<any>();

  const goDashboard = () => {
    history.replace(HOME_ROUTE_PATH);
  };

  useEffect(() => {}, []);

  const submitDetails = () => {
    let userData: any = {
      username: username,
      password: password,
      rememberMe: rememberMe,
    };

    setIsSaving(true);
    new BaseApiServiceImpl("/api/v1/auth/login")
      .postRequestWithJsonResponse(userData)
      .then(async (response) => {
        setIsSaving(false);
        UserSessionUtils.setUserDetails(response?.user);
        UserSessionUtils.setUserAuthToken(response?.accessToken);
        goDashboard();
      })
      .catch((error) => {
        setIsSaving(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  const handleGoogleLogin = () => {
    // Implement Google login logic here
    console.log("Google login clicked");
  };

  const handleFacebookLogin = () => {
    // Implement Facebook login logic here
    console.log("Facebook login clicked");
  };

  return (
    <div
      className="login-page flex flex-column"
      style={{
        background: "linear-gradient(to right, #4b79a1, #283e51)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="login-panel card shadow-lg p-4 rounded-lg"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <div className="text-center mb-4">
          <h2 style={{ color: "#283e51" }}>Welcome Back</h2>
          <p className="text-muted">Please login to your account</p>
        </div>

        <div className="p-inputgroup mt-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-envelope"></i>
          </span>
          <InputText
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Email / Username"
            style={{ width: "100%" }}
          />
        </div>

        <div className="p-inputgroup mt-3 mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-lock"></i>
          </span>
          <InputText
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            style={{ width: "100%" }}
          />
        </div>

        <Messages ref={message} style={{ width: "100%" }} />

        <Button
          loading={isSaving}
          className="p-button-rounded p-button-primary w-full mb-3 mt-3"
          label="LOGIN"
          onClick={submitDetails}
          style={{
            backgroundColor: "#4b79a1",
            border: "none",
            width: "100%",
            fontSize: "1.1rem",
            padding: "0.75rem",
          }}
        />

        <div className="text-center mb-3">
          <p className="text-muted">or login with</p>
        </div>

        <div className="social-login-buttons text-center">
          <Button
            onClick={handleGoogleLogin}
            label="Google"
            icon={<FaGoogle />}
            className="p-button-outlined p-button-secondary mb-2"
            style={{
              width: "100%",
              color: "#db4437",
              borderColor: "#db4437",
              marginBottom: "0.5rem",
              fontWeight: "bold",
            }}
          />

          <Button
            onClick={handleFacebookLogin}
            label="Facebook"
            icon={<FaFacebook />}
            className="p-button-outlined p-button-secondary"
            style={{
              width: "100%",
              color: "#3b5998",
              borderColor: "#3b5998",
              fontWeight: "bold",
            }}
          />
        </div>

        <div className="text-center mt-3">
          <a href="#" className="text-muted" style={{ fontSize: "0.9rem" }}>
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
